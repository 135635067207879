import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const HeaderStyles = styled.header`
    nav ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr 0 1fr 1fr 1fr;
        justify-content: space-between;
        @media (min-width: 768px) {
            grid-template-columns: 0.05fr 0.8fr 0.05fr 0.05fr 0.05fr;
        }
    }
    nav ul li a {
        display: inline-block;
        padding: 1rem;
        text-decoration: none;
        color: ${({ theme }) => theme.color.text};
    }
    /* default hover effect */
    nav ul li a:hover {
        background-color: ${({ theme }) => theme.color.background};
    }
    @media (hover: hover) {
    /* when hover is supported */
        nav ul li a:hover {
            background-color: ${({ theme }) => theme.color.secondary};
        }
    }
`
export default function Header() {
    return (
    <HeaderStyles>
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <div></div>
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </HeaderStyles>)
}